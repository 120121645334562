<script>
import patchPaymentSuccessful from '@/api/sales/patchPaymentSuccessful';

export default {
  name: 'Debug',
  props: ['entityId'],
  mounted() {
    patchPaymentSuccessful({
      id: this.$route.params.entityId,
      sagepayTransactionId: this.$route.params.sagepayTransactionId,
    });
  },
};
</script>

<template>
  <div>
    <p>
      Sending a <i>paymentSuccessfulEvent</i> request for {{ this.$route.params.entityId }} with a sagepayTransacctionId
      of {{ this.$route.params.sagepayTransactionId }}.
    </p>
  </div>
</template>
