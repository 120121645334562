import axios from 'axios';

export default ({ id, sagepayTransactionId }) => {
  const data = {
    payment_successful: true,
    sagepay_transaction_id: sagepayTransactionId,
  };
  const saleData = Object.entries(data).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/sales/${id}`, saleData);
};
